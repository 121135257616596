<template>
  <div class="">
    <div class="icon-style-section">
        <div v-b-toggle.icon-heading-style-section-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Heading Styles</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="icon-heading-style-section-collapse" class="mt-2">

          <div class="px-3">
      
      <label for="button-text" class="editor-label">Text:</label>
      <input type="text" v-model="block.text" placeholder="Heading text" name="button-text" class="editor-input-control w-100">
     </div>
        <hr>
        <div class="px-3">
          <FontFamily  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync=" block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
        </div>
        <hr>
 <div class="px-3">
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Line Height" style="gap:4px">
<span class="" style="color: #B3AFB6;" ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5.83332H7.08333L4.16667 2.91666L1.25 5.83332H3.33333V14.1667H1.25L4.16667 17.0833L7.08333 14.1667H5V5.83332ZM8.33333 4.16666V5.83332H18.3333V4.16666H8.33333ZM8.33333 15.8333H18.3333V14.1667H8.33333V15.8333ZM8.33333 10.8333H18.3333V9.16666H8.33333V10.8333Z" fill="currentColor"></path></svg></span>
  <input type="number" v-model="block.style.lineHeight"  placeholder="Line Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Rotate" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.925 7.10834L4.75 5.92501C4 6.89168 3.53333 8.00834 3.39166 9.16668H5.075C5.19166 8.44168 5.48333 7.73334 5.925 7.10834ZM5.075 10.8333H3.39166C3.53333 11.9917 3.99166 13.1083 4.74166 14.075L5.91666 12.8917C5.48333 12.2667 5.19166 11.5667 5.075 10.8333ZM5.91666 15.2667C6.88333 16.0167 8.00833 16.4667 9.16666 16.6083V14.9167C8.44166 14.7917 7.74166 14.5083 7.11666 14.0583L5.91666 15.2667ZM10.8333 3.39168V0.833344L7.04166 4.62501L10.8333 8.33334V5.07501C13.2 5.47501 15 7.52501 15 10C15 12.475 13.2 14.525 10.8333 14.925V16.6083C14.125 16.2 16.6667 13.4 16.6667 10C16.6667 6.60001 14.125 3.80001 10.8333 3.39168Z" fill="currentColor"></path></svg></span>
  <input type="number"  v-model="block.style.rotate" placeholder="Rotate" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
 <hr>
 <div class="px-3 mb-3">
  <SelectColor v-model="block.style.color"  :opacity.sync="block.style.textOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
 </div>

        </b-collapse>
      
      </div>
    <div class="icon-style-section">
        <div v-b-toggle.icon-style-section-collapse style="background: #F7F7F7;  cursor: pointer; border-bottom: 1px solid #D2D8E0; border-top: 1px solid #D2D8E0;" class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center">
          <div style="color: #18191C; font-weight: 500; font-size: 16px" class="">Icon Styles</div>
          <div class="button-chevron" style="color: #B3AFB6; font-weight: 500; font-size: 16px" >
          <i class="fa fa-chevron-down " aria-hidden="true"></i>
        </div>
        </div>
        <b-collapse accordion="button-accordion" id="icon-style-section-collapse" class="mt-2">
          <div class="px-3">
            <div class="">
                          <!-- Whatsapp start -->
                          <div class="mb-2">
                              <div class=" d-flex justify-content-between align-items-center ">
                                  <p class="m-0 p-0">
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.373002 20.0326C0.313845 20.0328 0.255504 20.0188 0.202821 19.9919C0.150137 19.965 0.10463 19.9259 0.0700735 19.8779C0.0355173 19.8299 0.0129092 19.7743 0.00412472 19.7158C-0.00465973 19.6573 0.000632942 19.5976 0.0195634 19.5415L1.48912 15.2184C0.332967 13.2951 -0.148456 11.0409 0.121099 8.81306C0.390654 6.58518 1.39578 4.51092 2.97728 2.91878C4.85492 1.04947 7.39655 0 10.0461 0C12.6955 0 15.2372 1.04947 17.1148 2.91878C18.77 4.59774 19.7803 6.80645 19.9681 9.15659C20.156 11.5067 19.5093 13.8479 18.1419 15.7684C16.7744 17.689 14.7737 19.066 12.4915 19.6574C10.2092 20.2489 7.79151 20.0168 5.66342 19.0021L0.44741 20.0326H0.373002ZM5.71178 18.2282C5.77002 18.2286 5.82736 18.2426 5.8792 18.2692C7.84754 19.251 10.1007 19.5016 12.2366 18.9763C14.3726 18.451 16.2525 17.1839 17.5409 15.4012C18.8294 13.6184 19.4426 11.4359 19.2712 9.24301C19.0998 7.05009 18.1549 4.98937 16.6051 3.42847C15.7438 2.56701 14.7213 1.88365 13.5959 1.41742C12.4705 0.951185 11.2642 0.711217 10.0461 0.711217C8.82788 0.711217 7.62165 0.951185 6.49623 1.41742C5.37081 1.88365 4.34826 2.56701 3.48698 3.42847C2.00516 4.92581 1.07363 6.88053 0.843963 8.97459C0.614293 11.0686 1.09999 13.1788 2.22204 14.9617C2.25182 15.0089 2.27063 15.0621 2.27707 15.1175C2.28351 15.1728 2.27742 15.229 2.25925 15.2817L0.934783 19.1844L5.64481 18.2468L5.71178 18.2282Z" fill="#18191C"/>
<path d="M12.7136 15.1961C12.5996 15.2023 12.4853 15.2023 12.3713 15.1961C10.3958 14.9803 7.90681 13.0271 7.20365 12.3388C6.51537 11.6505 4.55844 9.14669 4.34638 7.17115C4.17524 5.5937 5.22811 4.52222 5.68945 4.13158C5.89573 3.95438 6.16186 3.86257 6.43353 3.87487L7.43803 3.90836C7.51149 3.91108 7.5825 3.9355 7.64209 3.97854C7.70169 4.02158 7.74719 4.08131 7.77287 4.15018C7.99609 4.74173 8.71785 6.70982 8.71785 7.21952C8.71785 7.66597 8.32349 8.01196 7.97377 8.31704C7.89936 8.38028 7.79891 8.46957 7.73939 8.53282C8.1154 9.21999 8.5658 9.86377 9.08245 10.4525C9.67129 10.9691 10.3151 11.4195 11.0022 11.7956C11.0654 11.7324 11.1547 11.6319 11.218 11.5612C11.523 11.2115 11.869 10.8171 12.3341 10.8171C12.8438 10.8171 14.8119 11.5389 15.4034 11.7621C15.4723 11.7878 15.532 11.8333 15.5751 11.8929C15.6181 11.9525 15.6425 12.0235 15.6452 12.097L15.6787 13.1015C15.6864 13.3724 15.5951 13.6369 15.422 13.8456C15.0933 14.2511 14.6814 14.5815 14.2142 14.8145C13.747 15.0474 13.2353 15.1775 12.7136 15.1961ZM7.73195 11.8031C8.34581 12.4169 10.7083 14.2585 12.4531 14.4483C13.6771 14.5785 14.5328 13.7339 14.8454 13.3656C14.9011 13.2962 14.9302 13.2091 14.9272 13.1201L14.9012 12.376C14.0648 12.0406 13.2042 11.7695 12.3266 11.5649C12.2076 11.5649 11.9137 11.9109 11.7872 12.056C11.5342 12.3462 11.3333 12.5732 11.0431 12.5732C10.5706 12.5732 8.92247 11.3529 8.55415 10.9846C8.18584 10.6162 6.9767 8.95695 6.9767 8.49934C6.9767 8.22031 7.20365 8.0194 7.49384 7.75525C7.63522 7.62876 7.97377 7.33857 7.98493 7.2158C7.78034 6.33828 7.5092 5.47762 7.17389 4.64128L6.42981 4.61523C6.3408 4.61225 6.25367 4.64129 6.18426 4.69708C5.81222 5.0096 4.96769 5.86529 5.10162 7.08558C5.29136 8.84161 7.13296 11.178 7.74683 11.8068L7.73195 11.8031Z" fill="#18191C"/>
</svg>
  <span class="editor-label ml-2"> Whatsapp </span>
                                  </p>
                                  <b-form-checkbox
                       :id="`showWhatsapp-${Date.now()}`"
            v-model="block.isWhatsappVisible"
                  ></b-form-checkbox>
          
        </div>
       
                          </div>
                          <!-- Whatsapp End -->
                          <!-- Telegram start -->
                          <div class="my-2">
                              <div class=" d-flex justify-content-between align-items-center ">
                                  <p class="m-0 p-0">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#18191C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.5266 9.89446C7.4418 8.62435 9.38573 7.78702 10.3584 7.38246C13.1355 6.22737 13.7125 6.02672 14.0887 6.02009C14.1714 6.01863 14.3563 6.03913 14.4762 6.13635C14.5773 6.21844 14.6052 6.32934 14.6185 6.40717C14.6318 6.485 14.6484 6.6623 14.6352 6.80083C14.4847 8.38207 13.8335 12.2193 13.5022 13.9903C13.3621 14.7397 13.0861 14.991 12.8188 15.0156C12.2381 15.069 11.7972 14.6318 11.2347 14.2631C10.3546 13.6861 9.85735 13.327 9.00303 12.764C8.01572 12.1134 8.65575 11.7558 9.21842 11.1714C9.36567 11.0184 11.9243 8.69115 11.9739 8.48003C11.98 8.45362 11.9858 8.3552 11.9273 8.30323C11.8689 8.25126 11.7826 8.26903 11.7203 8.28316C11.632 8.3032 10.226 9.23253 7.50222 11.0711C7.10313 11.3452 6.74164 11.4787 6.41776 11.4717C6.06071 11.464 5.37388 11.2698 4.8633 11.1039C4.23705 10.9003 3.73932 10.7927 3.78266 10.4469C3.80524 10.2669 4.05321 10.0827 4.5266 9.89446Z" fill="white"/>
</svg>
  
  <span class="editor-label ml-2"> Telegram </span>
                                  </p>
                                  <b-form-checkbox
                        :id="`showisTelegramVisible-${Date.now()}`"
            v-model="block.isTelegramVisible"
                  ></b-form-checkbox>
        </div>
      
                          </div>
                          <!-- Telegram End -->
                          <!-- Copy Link start -->
                          <div class="my-2">
                              <div class=" d-flex justify-content-between align-items-center ">
                                  <p class="m-0 p-0">
                                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3337 0.833344H3.33366C2.41699 0.833344 1.66699 1.58334 1.66699 2.50001V14.1667H3.33366V2.50001H13.3337V0.833344ZM15.8337 4.16668H6.66699C5.75033 4.16668 5.00033 4.91668 5.00033 5.83334V17.5C5.00033 18.4167 5.75033 19.1667 6.66699 19.1667H15.8337C16.7503 19.1667 17.5003 18.4167 17.5003 17.5V5.83334C17.5003 4.91668 16.7503 4.16668 15.8337 4.16668ZM15.8337 17.5H6.66699V5.83334H15.8337V17.5Z"
                          fill="#4D4950"
                        />
                      </svg>
  
  <span class="editor-label ml-2"> Copy Link </span>
                                  </p>
                                  <b-form-checkbox
                        :id="`showisCopyLinkVisible-${Date.now()}`"
            v-model="block.isCopyLinkVisible"
                  ></b-form-checkbox>
        </div>
      
                          </div>
                          <!-- Telegram End -->
                        </div>
          </div>
          <hr>
          <div class="px-3">
            <div class="d-flex mt-1 align-items-center justify-content-between">
            <span class="editor-label">Icon Size: {{isDesktopView ? '' : '(Mobile)'}}</span>
              <div class="select-per-page-count ">
                <select name="" v-if="isDesktopView" v-model="block.style.IconSize" id="">
                  <option value="">Select</option>
                  <option v-for="index in 5" :key="index" :value="index">{{ index }}</option>
                </select>
                <select name="" v-else v-model="block.style.IconMobileSize" id="">
                  <option value="">Select</option>
                  <option v-for="index in 5" :key="index" :value="index">{{ index }}</option>
                </select>
              </div>
           </div>
           <div class="mt-3">
            <label  for="button-text" class="editor-label">Icon Position:</label>
    <div class="d-flex text-align-section">
      <label title="Start" for="left" @click="block.style.position='left'" :class="block.style.position=='left'?'active-editor-align':''" class="editor-text-left-control">
        <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label title="Center" for="center"  @click="block.style.position='center'" :class="block.style.position=='center'?'active-editor-align':''"  class="editor-text-center-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label for="end" title="Right" @click="block.style.position='right'" :class="block.style.position=='right'?'active-editor-align':''" class="editor-text-right-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
       
    </div>
           </div>
          </div>
          <hr>
          <div class="px-3">
            <SelectColor :label="'Icon Color:'" v-model="block.style.IconColor" :isOpacity="false"></SelectColor>
            
          </div>
          <hr class="mb-0">
        </b-collapse>
        </div>
        <div class="px-3 my-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
  </div>
  </template>
  <script>
  import fontFamilyList from "../../../staticPages/fontFamilyList";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import Padding from "../MiniComponents/Padding.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
  export default {
    components:{
      SelectColor,
      FontFamily,
      FontStyles,
      Padding
    },
    props: {
      block: Object,
      index: Number,
       isDesktopView:Boolean,
    },
    data() {
      return {
       
        showBgOpacity:true,
      };
  
    },
    computed:{
      getFontFamilyList() {
return fontFamilyList;
},
textBlockFont(){
      return this.block.style.fontFamily
    },
    },
    methods:{
      checkHexCode(color){
        if(color){
          let pattern = /^#[0-9A-F]{6}$/i;
             return  color.match(pattern) ? true : false
        }
              
          },
      BgColorOutput(value){
        let isHex = this.checkHexCode(value)
        if(isHex){
          this.showBgOpacity = true
          return value
        } else{
          this.showBgOpacity = false
          return 'Gradient input'
        }
      },
      
    },
    watch:{
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  }
  };
  </script>
  <style >
      .socialInput {
          background: transparent;
          border: none;
          outline: none;
          border-bottom:  1px solid #E0DEE2;
      }
      .socialInput:focus {
          border: none;
          outline: none;
          border-bottom:  1px solid #E0DEE2;
      }
      .socialInput::placeholder {
         font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      
      text-align: start;
      
      color: #D1D1D1;
      }

  </style>
  